

























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { adListServer } from '@/services/ad-list-service';
import { rankingListServer } from '@/services/ranking-list-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
import { getMoney } from '@/services/utils';

@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  private tableData: any[] = [];
  public franchise: any[] = [];
  public loading: Boolean = true;
  public kindshow: Boolean = false;
  private currentRow: any = null;

  @Inject(adListServer) private adlistServer!: rankingListServer;

  public async created(): Promise<void> {
    await this.getList();
    this.franchiseList();
  }

  //franchise
  public async franchiseList(): Promise<void> {
    const res = await this.adlistServer.franchise();
    this.franchise = res;
  }

  public search() {
    this.form.current = 1;
    if(this.form.franchise){
      this.form.franchiseParam = this.form.franchise.replace(new RegExp("\\&","g"),"%26")
    }
    this.getList();
  }
  public clearEvent(){
    this.form.franchiseParam = ''
  }

  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }

  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }

  //获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.adlistServer.getAdList(this.form);
    this.loading = false;
    //let data: any = res.records
    let data: any = res.records.map((element: any) => {
      if (element.incrementalSales) {
        element.incrementalSales = '' + getMoney(element.incrementalSales);
      }
      return element;
    });
    this.tableData = data;
    this.form.total = res.total;
    this.form.size = res.size;
    this.form.current = res.current;
  }

  //下载
  public async downLoadAll() {
    this.loading = true;
    var data_ = {
      type: '17'
    };
    const res = await this.adlistServer.downLoadAll(data_, 'AD信息.xlsx');
    this.loading = false;
  }

  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }

}
