import { render, staticRenderFns } from "./adList.vue?vue&type=template&id=080d7074&scoped=true&"
import script from "./adList.vue?vue&type=script&lang=ts&"
export * from "./adList.vue?vue&type=script&lang=ts&"
import style0 from "./adList.vue?vue&type=style&index=0&id=080d7074&lang=scss&scoped=true&"
import style1 from "./adList.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080d7074",
  null
  
)

export default component.exports